import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import {
  constructSourceSet,
  constructSourceSetSizes,
  getImageKitUrl,
} from '@utils/common.utils';
import {
  BrowserProps,
  PosterType,
  VideoType,
  VideoPosterType,
} from '../../types';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import { StateContext } from '@store/store';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { getPosterURL } from '@invideoio/web-shared/utils/common';

interface HeroVideoProps extends BrowserProps {
  video: VideoType;
  poster: VideoPosterType;
}

const HeroVideo = (props: HeroVideoProps) => {
  const { browser, video, poster } = props;
  const videPoster: PosterType = poster?.desktop || { defaultURL: poster.url };

  const globalState = useContext(StateContext);

  const { platform } = globalState;

  const posterURL = getPosterURL(videPoster, browser);

  const desktopURL = getImageKitUrl(posterURL, { width: 1102 });
  const mobileURL = getImageKitUrl(posterURL, { width: 450 });
  const tabletURL = getImageKitUrl(posterURL, { width: 700 });

  const srcSet = constructSourceSet([
    { url: mobileURL, intrinsicWidth: 420 },
    { url: tabletURL, intrinsicWidth: 700 },
    { url: desktopURL, intrinsicWidth: 1102 },
  ]);
  const srcSetSizes = constructSourceSetSizes([
    { query: '(min-width: 1120px)', size: '1102px' }, // desktop
    { query: '(min-width: 720px) and (max-width: 1199px)', size: '693px' }, // tablet
    { query: '', size: '410px' }, // mobile
  ]);

  return (
    <div className={ivclass('iv-w-full iv-h-full')}>
      {video?.url && platform === PlatformType.Desktop && (
        <VideoComponent
          autoPlay
          loop
          muted
          width="100%"
          height="100%"
          poster={posterURL}
          loading="preload"
          className="iv-h-full iv-rounded-[20px] iv-bg-grey-100"
          style={{ boxShadow: '0px 20px 100px 0px rgba(0, 0, 0, 0.35)' }}
        >
          <source src={video?.url} />
        </VideoComponent>
      )}
      {(!video?.url || platform !== PlatformType.Desktop) && posterURL && (
        <ImageComponent
          elementype="native"
          src={posterURL}
          srcSet={srcSet}
          sizes={srcSetSizes}
          className="iv-h-full iv-object-contain"
          alt="hero"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default HeroVideo;
