import { PageType } from '@invideoio/web-shared/types/Analytics';
import { GetServerSidePropsContext } from 'next';
import React, { useContext, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { DispatchContext, StateContext } from '../../store/store';

import AppLayout from '../../layouts/AppLayout';
import HomepageSEO from '@components/common/SEO/HomepageSEO';

import { Referrer } from '@invideoio/web-shared/utils/utils.constant';
import { registerAndSendPageViewEvent } from '@invideoio/web-shared/utils/analytics/Register';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { getHomepageGen3 } from '../../lib/API';
import {
  setCommonAnalyticsProperties,
  sendAmplitudeAnalyticEvent,
  AmplitudeEvents,
} from '@utils/analytics';
import HeaderWithCTA from '@components/header-ai';
import HomepageAI from '@layouts/HomepageAI';
import { HomepageResponseType } from '../../types';
import useLazyload from '@hooks/useLazyload';
import StudioBanner from '@invideoio/web-shared/components/StudioBanner';
import { setCacheControlHeaders } from '@utils/server.utils';
import { BaseAppType } from '@constants/common';
import { baseAppTypeLoaded } from '@store/actions';
import Banner from './components/Banner';

interface AiWaitlistPageProps {
  pageData: HomepageResponseType;
}

function AiWaitList(props: AiWaitlistPageProps) {
  const { pageData } = props;
  const globalState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { lang } = useTranslation('home');
  const { platform, browser } = globalState;

  useLazyload();

  useEffect(() => {
    setCommonAnalyticsProperties();

    AnalyticsUtils.setReferrer(Referrer.WEBSITE);

    registerAndSendPageViewEvent(PageType.AI, {
      location: window.location.href,
    });

    sendAmplitudeAnalyticEvent(AmplitudeEvents.appLoad);
    dispatch(baseAppTypeLoaded(BaseAppType.AI));
  }, []);

  if (!pageData) {
    return null;
  }

  return (
    <AppLayout platform={platform}>
      <HomepageSEO seo={pageData.SEO} locale={lang} />
      <StudioBanner browser={browser} />
      {/* <Banner /> */}
      <HeaderWithCTA isCTAIntersected={true} pageType={PageType.HOMEPAGE} />

      <HomepageAI homepageState={pageData} />
    </AppLayout>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let pageData = null;

  let maxage = 3600;

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    maxage = 300; // 5mins
  }

  setCacheControlHeaders(context, maxage);

  try {
    const homepageStrpiUrl = '/homepage-ai';
    pageData = await getHomepageGen3(homepageStrpiUrl, context.locale);
    return { props: { pageData } };
  } catch (error) {
    console.log(error);

    return {
      notFound: true,
    };
  }
}

export default AiWaitList;
