import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import useTranslation from 'next-translate/useTranslation';

import { AppType } from '@invideoio/web-shared/constants/common';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { HEADER_ACTION_LINK_ANIMATE } from '@invideoio/web-shared/constants/header';
import { exploreLinks } from '@constants/headerExplore';
import { RedirectContext, StateContext } from '../../store/store';
import { PageType } from '@invideoio/web-shared/types/Analytics';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import HeaderConfig from '@invideoio/web-shared/constants/header';
import dynamic from 'next/dynamic';
import { useAuthFunctions } from '@hooks/useAuthFunctions';

const Header = dynamic(
  () =>
    import(
      /* webpackChunkName: "Header" */
      '@invideoio/web-shared/components/Header'
    ),
);

const HeaderWithCTA = (props: {
  isCTAIntersected?: boolean;
  pageType?: PageType;
}) => {
  const assetUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL;
  const host = process.env.NEXT_PUBLIC_BASE_HOST;
  const { goToAiSignup } = useContext(RedirectContext);

  const { t, lang } = useTranslation();
  const { isCTAIntersected, pageType } = props;

  const globalState = useContext(StateContext);
  const { platform, os, config } = globalState;

  const {
    onAILoginClick,
    onAISignupClick,
    onStudioLoginClick,
    onStudioSignupClick,
  } = useAuthFunctions();

  const onLoginClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickLogin, {
      'Button Position': 'Top Right - Header',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.TopRightHeader,
        type: ClickCtaType.ClickLogin,
        feature_utm: 'na',
        product: AnalyticsProductType.AI,
      },
    });

    goToAiSignup({ page: 'login', ux_source: ClickCtaUxSource.TopRightHeader });
  };

  const onSignupClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Top Right - Header',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.TopRightHeader,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product: AnalyticsProductType.AI,
      },
    });

    goToAiSignup({ ux_source: ClickCtaUxSource.TopRightHeader });
  };

  const headerActionLinkAnimate =
    pageType === PageType.HOMEPAGE
      ? isCTAIntersected
        ? HEADER_ACTION_LINK_ANIMATE.SCROLL_UP
        : HEADER_ACTION_LINK_ANIMATE.SCROLL_DOWN
      : undefined;

  return (
    <div className="iv-h-[56px]">
      <div
        className={ivclass(
          'iv-fixed',
          'iv-w-full',
          'iv-z-20',
          'iv-backdrop-blur-[30px]',
          'iv-bg-white-75',
        )}
      >
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <Header
            os={os}
            logoURL={`${assetUrl}/logos/invideo-ai-light.svg`}
            logoRedirectURL={`${host}/ai/`}
            menu={HeaderConfig.MENU_AI}
            platform={platform}
            onLogin={onLoginClick}
            onSignUp={onSignupClick}
            appType={AppType.Homepage}
            socialLinks={globalState.socialLinks}
            exploreLinks={exploreLinks[config?.env?.siteId][lang]}
            headerActionLinkAnimate={headerActionLinkAnimate}
            t={t}
            authFunctions={{
              onAILogin: onAILoginClick,
              onAISignUp: onAISignupClick,
              onStudioLogin: onStudioLoginClick,
              onStudioSignUp: onStudioSignupClick,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderWithCTA;
