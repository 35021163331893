import ErrorBoundary from '@components/ErrorBoundary';
import HeroSectionAi from '@components/hero-section-ai';
import PricingPlanAI from '@components/pricing-plan/ai';
import SuperbVideoCTA from '@components/superb-videos-cta';
import WorkflowV2 from '@components/workflow-v2';
import { HOMEPAGE } from '@constants/homepage';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Footer from '@invideoio/web-shared/components/Footer';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import FooterConfig from '@invideoio/web-shared/constants/footer';
import { StateContext } from '@store/store';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { HomepageResponseType } from 'src/types';

const AppPromo = dynamic(
  () =>
    import(
      /* webpackChunkName: "AppPromo" */
      '@components/app-promo'
    ),
);

const LetsConnect = dynamic(
  () =>
    import(
      /* webpackChunkName: "LetsConnect" */
      '@components/lets-connect-v2'
    ),
);

const SwitchBackCards = dynamic(
  () =>
    import(
      /* webpackChunkName: "SwitchBackCards" */
      '@components/switch-back-cards/SwitchBackCards'
    ),
);

const Achievements = dynamic(
  () =>
    import(
      /* webpackChunkName: "Achievements" */
      '@components/achievements'
    ),
);

const FeaturesCard = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeaturesCard" */
      '@components/features-card'
    ),
);

interface HomePageV2Props {
  homepageState: HomepageResponseType;
}

function HomepageAI({ homepageState }: HomePageV2Props) {
  const { t } = useTranslation('home');

  const [isCTAIntersected, setIsCTAIntersected] = useState(true);

  const globalState = useContext(StateContext);
  const { platform, os, browser } = globalState;

  return (
    <React.Fragment>
      <div className={ivclass('iv-max-w-[100vw] iv-overflow-hidden')}>
        <div
          className={ivclass(
            'xl:iv-container xl:iv-mx-auto iv-pb-[100px] sm:iv-pb-[0px]',
          )}
        >
          <ErrorBoundary>
            <HeroSectionAi
              browser={browser}
              os={os}
              platform={platform}
              heroSection={homepageState.heroSection}
              onCTAIntersection={setIsCTAIntersected}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className={ivclass('iv-max-w-[100vw]')}>
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <Achievements layoutV3 />

          {homepageState.Workflow && (
            <ErrorBoundary>
              <WorkflowV2
                platform={platform}
                browser={browser}
                makeWorkFlow={homepageState.Workflow}
              />
            </ErrorBoundary>
          )}

          {homepageState.feature && (
            <ErrorBoundary>
              <FeaturesCard
                platform={platform as PlatformType}
                browser={browser}
                sectionHead={homepageState.feature.sectionHead}
                card1={homepageState.feature.card1}
                card2={homepageState.feature.card2}
                card3Desktop={homepageState.featureCard3Desktop}
                card3Mobile={homepageState.featureCard3Mobile}
                card4={homepageState.feature.card4}
                title={homepageState.feature.title}
                layoutV3
                isAiPage
              />
            </ErrorBoundary>
          )}
        </div>

        <div className={ivclass('xl:iv-mb-[-10px] lg:iv-mb-[-10px]')}>
          <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
            {homepageState.ScrollCards && (
              <ErrorBoundary>
                <SwitchBackCards
                  data={homepageState.ScrollCards}
                  browser={browser}
                />
              </ErrorBoundary>
            )}

            <ErrorBoundary>
              <PricingPlanAI
                platform={platform as PlatformType}
                theme="light"
                pricingTitle={{
                  plainHeading: 'The right plans,',
                  highlightedHeading: 'for the right price',
                }}
              />
            </ErrorBoundary>
          </div>
        </div>

        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <AppPromo
            os={os}
            isNotDesktop={platform !== 'desktop'}
            promoType="appPromoAI"
          />
          {homepageState.LetsConnect && (
            <LetsConnect
              data={homepageState.LetsConnect}
              isMobile={platform === 'mobile'}
              browser={browser}
            />
          )}
        </div>
        <SuperbVideoCTA
          heading={{
            plainHeading: t(`superbVideo.headingAI.plainHeading`),
            highlightedHeading: t(`superbVideo.headingAI.highlightedHeading`),
          }}
          buttonText={t(`superbVideo.buttonText`)}
          CTAUrl={HOMEPAGE.superbVideo.AiCTAUrl}
        />
        <Footer
          os={os}
          platform={platform}
          socialLinks={globalState.socialLinks}
          footer={FooterConfig.FOOTER_AI}
        />
      </div>
    </React.Fragment>
  );
}

export default HomepageAI;
